import { fireSuccessAlert } from "@/utils";
import { PredefinedTextPayload, PredefinedTextsParams } from "@/models/predefinedTexts";
import { createPredefinedTextService, getPredefinedTextsService, updatePredefinedTextService } from "@/services/gate/predefinedTexts";

export function getPredefinedTexts(params: PredefinedTextsParams, all?: boolean) {
    return async function () {
        const filters = all ? params : { ...params, active: 1 };
        const response = await getPredefinedTextsService(filters);

        if (response.success) {
            return response;
        }
    };
}

export function createPredefinedText(payload: PredefinedTextPayload) {
    return async function () {
        const response = await createPredefinedTextService(payload);

        if (response.success) {
            fireSuccessAlert("Se ha creado texto predefinido exitosamente.", "Creado exitosamente");
            return response.success;
        }
    };
}

export function updatePredefinedText(payload: PredefinedTextPayload) {
    return async function () {
        const response = await updatePredefinedTextService(payload);

        if (response.success) {
            fireSuccessAlert("Se ha editado texto predefinido exitosamente.", "Editado exitosamente");
            return response.success;
        }
    };
}